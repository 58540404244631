.login-container {
  position: relative;
  max-width:600px;
    margin: 10% auto;
    padding: 0px;
    display: flex;
    background:transparent;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-family: "Poetsen One";
      position: relative;
   
      overflow: visible;
  
  }
  
  .backdrop{
    height:100vh; width:100vw; background:#f7c41e; top:0; position:absolute;
  }
  .loginbox label{ background:none; } 
  .loginbox input{ background:#FFF;} 
  .loginbox{ 
   
    background-color:#f7c41e !important;
  }
  
  
  .loginbox::before {
    content: "";
    position: absolute;
    inset:0;
    border-radius: 50%;
    transform-origin: inherit;
  }
  
  
  .loginbox:hover,
  .loginbox:hover::before{
    transform:rotate(0.25deg);
    transition:.2s  cubic-bezier(0.5,120,0.5,-120); 
  }
  .loginbox:hover::before {
     transition-delay:.2s; 
  }
  
  
  .videobg{
    position:absolute; top:0; left:0;
    width: 100%; 
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
    z-index:0;
  }
  .bg{
  display: flex; min-width:300px;
    align-items: center;
    justify-content: center;
      
  }
  
  @media screen and (max-width: 440px) {
  .login-container {
    display: flex;
    flex-direction: column; /* Stack child elements vertically *//* Set the container height to the full viewport height */
  }
  }