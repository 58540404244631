.right-panel .MuiDrawer-paper{ background:#FFF; box-shadow:0 0 8px #333; }

.main {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.job {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.status {
    font-weight: bold;
}

.description {
    margin-top: 5px;
}

.link {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
